import React from "react";
import { withAuth } from "@okta/okta-react";
import { Button } from "semantic-ui-react";
import HLSPlayer from "hls.js";
import DashJS from "dashjs";

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.InitializeHLS = this.InitializeHLS.bind(this);
    this.InitializeDash = this.InitializeDash.bind(this);
  }

  componentWillUnmount() {
    if(this.player) {
      this.player.destroy ? this.player.destroy() : this.player.reset();
    }
  }

  InitializeHLS(element) {
    if(!element) { return; }

    const playoutOptions = this.props.playoutOptions.hls.playoutMethods;
    const playoutUrl = (playoutOptions["aes-128"] || playoutOptions.clear).playoutUrl;

    const player = new HLSPlayer();
    player.loadSource(playoutUrl);
    player.attachMedia(element);

    this.player = player;
  }

  InitializeDash(element) {
    this.player = DashJS.MediaPlayer().create();

    const playoutOptions = this.props.playoutOptions.dash.playoutMethods;
    const playoutUrl = (playoutOptions.widevine || playoutOptions.clear).playoutUrl;

    if(playoutOptions.widevine) {
      const widevineUrl = playoutOptions.widevine.drms.widevine.licenseServers[0];

      this.player.setProtectionData({
        "com.widevine.alpha": {
          "serverURL": widevineUrl,
          "httpRequestHeaders": {
            "Authorization": `Bearer ${this.props.authToken}`
          },
          "withCredentials": false
        }
      });
    }

    this.player.initialize(element, playoutUrl);
  }

  render() {
    return (
      <video
        className="video-player"
        controls
        autoPlay
        ref={this.props.protocol === "hls" ? this.InitializeHLS : this.InitializeDash}
      />
    );
  }
}

class Playout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      videoInitialized: false,
      content: "",
      error: "",
      playoutOptions: undefined,
      protocol: "hls"
    };

    this.SaveVideo = this.SaveVideo.bind(this);
    this.LoadVideo = this.LoadVideo.bind(this);

    this.InitializeClient();
  }

  async InitializeClient() {
    if(!this.props.client) {
      throw Error("No client specified in playout");
    }

    const oauthToken = await this.props.auth.getIdToken();

    console.log("Initializing client");
    console.log("ID Token:", oauthToken);

    if(!this.props.client.signer) {
      await this.props.client.SetSignerFromOauthToken({token: oauthToken});
    }

    const savedVideos = (await this.props.client.userProfileClient.UserMetadata({
      metadataSubtree: "public/saved_videos",
      resolveLinks: true,
      resolveIncludeSource: true
    })) || {};

    this.setState({
      initialized: true,
      oauthToken,
      savedVideos
    });
  }

  async SaveVideo() {
    if(!this.state.initialized) { return; }

    const client = this.props.client;

    // Content ID specified may be object ID or version hash
    const contentObjectId = this.state.content.startsWith("iq__") ? this.state.content : "";
    let contentVersionHash = this.state.content.startsWith("hq__") ? this.state.content : "";
    if(!contentVersionHash) {
      contentVersionHash = await client.LatestVersionHash({objectId: contentObjectId});
    }

    // Avoid saving duplicates
    if(Object.values(this.state.savedVideos).find(metadata => metadata["."].source === contentVersionHash)) {
      return;
    }

    // Retrieve the slug to save the video
    const slug = await client.ContentObjectMetadata({
      versionHash: contentVersionHash,
      metadataSubtree: "public/asset_metadata/slug"
    });

    const { libraryId, objectId } = await client.userProfileClient.UserWalletObjectInfo();

    const { write_token } = await client.EditContentObject({libraryId, objectId});

    await client.CreateLinks({
      libraryId,
      objectId,
      writeToken: write_token,
      links: [
        {
          autoUpdate: true,
          type: "metadata",
          path: `public/saved_videos/${slug}`,
          targetHash: contentVersionHash,
          target: "public/asset_metadata"
        }
      ]
    });

    await client.FinalizeContentObject({libraryId, objectId, writeToken: write_token});

    const savedVideos = (await client.userProfileClient.UserMetadata({
      metadataSubtree: "public/saved_videos",
      resolveLinks: true,
      resolveIncludeSource: true
    }) || {});

    this.setState({savedVideos});
  }

  async LoadVideo() {
    this.setState({
      videoInitialized: false,
      stateChannelToken: "",
      playoutOptions: undefined,
      error: ""
    });

    const objectId = this.state.content.startsWith("iq__") ? this.state.content : "";
    const versionHash = this.state.content.startsWith("hq__") ? this.state.content : "";

    if(!objectId && !versionHash) {
      this.setState({
        error: "Invalid object ID or version hash",
        videoInitialized: false,
        playoutOptions: undefined
      });
    }

    try {
      const playoutOptions = await this.props.client.PlayoutOptions({
        objectId,
        versionHash,
        protocols: ["hls", "dash"],
        drms: ["aes-128", "widevine"]
      });

      const stateChannelToken = await this.props.client.GenerateStateChannelToken({
        objectId,
        versionHash
      });

      this.setState({
        videoInitialized: true,
        playoutOptions,
        stateChannelToken
      });
    } catch(error) {
      this.setState({
        error: error.message,
        videoInitialized: false,
        playoutOptions: undefined
      });
    }
  }

  Video() {
    if(!this.state.videoInitialized) { return null; }

    return (
      <React.Fragment>
        <Video
          key={`video-${this.state.content}-${this.state.protocol}`}
          playoutOptions={this.state.playoutOptions}
          protocol={this.state.protocol}
          authToken={this.state.stateChannelToken}
        />
        <div className="protocol-controls">
          <Button
            primary={this.state.protocol === "hls"}
            onClick={() => this.setState({protocol: "hls"})}
          >
            HLS
          </Button>

          <Button
            primary={this.state.protocol === "dash"}
            onClick={() => this.setState({protocol: "dash"})}
          >
            Dash
          </Button>
        </div>
      </React.Fragment>
    );
  }

  UserInfo() {
    const privateKey = this.props.client.signer.signingKey.privateKey;
    const address = this.props.client.utils.FormatAddress(this.props.client.signer.address);

    return (
      <React.Fragment>
        <div className="user-info">
          <label>Address</label>
          <span>{ address }</span>
        </div>
        <div className="user-info">
          <label>Private Key</label>
          <span>{ privateKey }</span>
        </div>
      </React.Fragment>
    );
  }

  SavedVideos() {
    if(!this.state.savedVideos) { return; }

    const options = Object.values(this.state.savedVideos).map(metadata => {
      const versionHash = metadata["."].source;

      return (
        <option key={versionHash} value={versionHash}>{ metadata.display_title }</option>
      );
    });

    return (
      <select
        value={this.state.content}
        onChange={async event => {
          this.setState({
            content: event.target.value
          }, this.LoadVideo);
        }}
      >
        <option value="">Select from saved videos...</option>
        { options }
      </select>
    );
  }

  render() {
    if(!this.state.initialized) {
      return (
        <div className="playout-loading">
          Loading...
        </div>
      );
    }

    return (
      <div className="playout">
        <div className="playout-error">{this.state.error}</div>
        <div className="playout-input">
          <input
            value={this.state.content}
            placeholder="Object ID or Version Hash..."
            onChange={event => this.setState({content: event.target.value})}
            onKeyUp={event => event.keyCode === 13 ? this.LoadVideo() : undefined}
          />
          <Button primary onClick={this.LoadVideo}>Load Content</Button>
          { this.SavedVideos() }

          <Button primary onClick={this.SaveVideo}>Save Video</Button>
        </div>

        { this.Video() }

        { this.UserInfo() }
      </div>
    );
  }
}

export default withAuth(Playout);
