const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const URI = require("urijs");

const baseURL = "https://auth-sample.test.contentfabric.io/";
const redirectURL = URI(baseURL).path("implicit/callback").toString();

export default {
  configUrl: "https://main.net955305.contentfabric.io/config",
  trustAuthorityId: "ikms42f2YMiWdwmPB8Ts34vKm24Su9LJ",
  version: "okta-video-playout-sample@3eac7bd49f7b9b12d9fd32fff1941999995eefeb",
  oidc: {
    clientId: "0oa1i977nwlyriDNX0h8",
    issuer: "https://mgmdev.okta.com/oauth2/default",
    redirectUri: redirectURL,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  }
};
